"use client";

import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { AdSlot } from "@/components/AdSlot/AdSlot.component";
import { TopAdFallback } from "@/components/TopAd/components/TopAdFallback.component";
import { useTopAd } from "@/components/TopAd/top-ad.context";
import { useSite } from "@/contexts/site/site.context";
import { useSiteConfiguration } from "@/contexts/site-configuration/site-configuration.context";
type TopAdProps = {
  readonly className?: string;
};

/**
 * The top banner section is handled in this way:
 *
 * 1) Initially we display or hide this section based on the default settings from the API
 * 2) If at some page we do not receive a banner from GAM we show a fallback banner instead (only if the section is already expanded)
 * 3) If the section is hidden but we at some point receive a banner from GAM - we expand the section for the rest of the user session
 *
 * This way we limit "content jumping" across pages, limiting it to the first page render only.
 */
export function TopAd({
  className
}: TopAdProps) {
  // We need to keep state out in _app.page.tsx, to avoid the state to rerender with each route change
  const {
    isHidden: isHiddenInSession,
    setIsHidden: setIsHiddenInSession
  } = useTopAd();
  const [isTopEmpty, setIsTopEmpty] = useState<boolean | null>(null);
  const {
    isTopBannerExpanded
  } = useSite();
  const isHiddenInConfig = useRef(!isTopBannerExpanded);
  const siteConfiguration = useSiteConfiguration();
  const hideFallbackBanner = Boolean(siteConfiguration.hideFallbackBanner);
  useEffect(() => {
    // If banner has been shown once, don't ever hide again cf. 3).
    // This component does remount from time to time, so we need this check.
    if (isHiddenInSession === false) {
      return;
    }
    setIsHiddenInSession(isHiddenInConfig.current);
  }, [isHiddenInSession, setIsHiddenInSession]);
  useEffect(() => {
    // First mount - we need to wait for isHiddenInSession and isTopEmpty to be initialised
    if (isHiddenInSession === null || isTopEmpty === null) {
      return;
    }

    // If banner has been shown once, don't ever hide again cf. 3)
    if (!isHiddenInSession) {
      return;
    }

    // If the banner is hidden but we at some point receive a banner from GAM - we expand the section for the rest of the user session
    if (!isTopEmpty) {
      setIsHiddenInSession(false);
    }
  }, [isHiddenInSession, isTopEmpty, setIsHiddenInSession]);

  // If isHiddenInConfig is false, we don't ever want to hide it.
  // So only if banner does not have top banner expanded, we can assume that a non-initialsed banner (isHiddenInSession === null)
  // or isHiddenInSession means that it should be hidden.
  const isHidden = isHiddenInConfig.current && (isHiddenInSession === null || isHiddenInSession);
  return <div className={clsx(className, "w-full bg-nero", isHidden && "hidden")} data-sentry-component="TopAd" data-sentry-source-file="TopAd.component.tsx">
      <div className="container h-[160px] max-w-[320px] p-0 lg:h-[180px] lg:max-w-[930px]">
        <AdSlot className="h-[160px] w-[320px] lg:h-[180px] lg:w-[930px]" domId="top" onBeforeShow={(isEmpty: boolean) => setIsTopEmpty(isEmpty)} data-sentry-element="AdSlot" data-sentry-source-file="TopAd.component.tsx" />
        {!hideFallbackBanner ? <TopAdFallback className={clsx((isTopEmpty === null || !isTopEmpty) && "hidden")} /> : null}
      </div>
    </div>;
}
"use client";

import { usePathname } from "next/navigation";
import type { ReactNode } from "react";

type Path = `/${string}`;

type DisplayProps = {
  readonly on?: Path | ReadonlyArray<Path> | null;
  readonly notOn?: Path | ReadonlyArray<Path> | null;
  readonly children: ReactNode;
};

export function Display({ children, on = null, notOn = null }: DisplayProps) {
  const pathname = usePathname();

  if (Array.isArray(notOn)) {
    const predicate = notOn.includes(pathname);
    return predicate ? null : children;
  }

  if (Array.isArray(on)) {
    const predicate = on.includes(pathname);
    return predicate ? children : null;
  }

  const predicate = on !== null ? on === pathname : notOn !== pathname;
  return predicate ? children : null;
}
